// --------------------------
// VARIABLES
// --------------------------
$darkest: rgb(67,68,99);
$lightpurple: rgb(92,94,117); 
$foam: rgb(245, 241, 224);
$vanilla: rgb(238, 231, 189);
$greysea: rgb(196,205,202);
$mainfont: 'Helvetica';
$specialfont: 'Suez One', serif;

// <link rel="preconnect" href="https://fonts.googleapis.com">
// <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
// <link href="https://fonts.googleapis.com/css2?family=Suez+One&display=swap" rel="stylesheet">

// CSS rules to specify families

// font-family: 'Suez One', serif;


// Header

nav {
  color: white;
  background-color: $darkest;
  display: flex;
  justify-content: space-between;
  font-family: 'Suez One', serif;
  padding: 25px;
  margin: 0px;
  font-size: x-large;
  
  a {
    text-decoration: none;
    color: white;

    div {
      margin: 10px;
      font-size: large;
    }
  }
}

// Main

body {
  background-color:$foam;
  margin: 0;
  box-sizing: border-box;
  font-family: $mainfont, sans-serif;
  // background-image: url('https://i.imgur.com/pwMiCg7.jpg');
}

.item-1 {
  grid-area: hi;
  text-align: center;
  color: $lightpurple;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 30px;
  margin: 25px;
  font-family: 'Suez One', serif;
  justify-self: center;
}

.item-3 {
  grid-area: blurb;
  text-align: center;
  color: $lightpurple;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 30px;
  margin: 25px;
  font-family: sans-serif;
  justify-self: center;
  font-size: large;
}

.item-2 {
  grid-area: avatar;
  text-align: center;
  color: $lightpurple;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 30px;
  margin: 25px;
  font-family: sans-serif;
  justify-self: center;
}

.avatar {
  height: auto;
  max-width: 225px;
}

.blurb {
  height: auto;
  size: large;
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    ' . hi hi hi . '
    '. blurb blurb blurb .'
    '. . avatar . .';
}

// About Me

.about {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    ' . . about . . '
    '. about-2 about-2 about-2 . ';
}

.about-header {
  grid-area: about;
  font-family: $specialfont, serif;
  color: $lightpurple;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 30px;
  margin: 25px;
  text-align: center;
}

.about-text {
  grid-area: about-2;
  color: $lightpurple;
  font-family: 'Helvetica';
}

// contact me

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    ' . . contact . . '
    ' . . contact-text . . '
    '. . profile-container . . ';
}

.contact {
  grid-area: contact;
  font-family: $specialfont, serif;
  color: $lightpurple;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  text-align: center;
}

.profile-container {
  grid-area: profile-container;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  max-width: 250px;
}

.contact-header {
  text-align: center;
}

.contact-text {
  grid-area: contact-text;
  color: $lightpurple;
  font-family: 'Helvetica Light';
  text-align: center;
  font-size: large;
}

.profile-img {
  height: auto;
  max-width: 225px;
}

// portfolio

.port-wrapper {
  color:$lightpurple;
  padding: 0 0 0 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    '. . Portfolio . . '
    'Project5 Project5 Project5 Project5 Project5'
    'Project4 Project4 Project4 Project4 Project4'
    'Project3 Project3 Project3 Project3 Project3'
    'Project2 Project2 Project2 Project2 Project2'
    'Project1 Project1 Project1 Project1 Project1';
}

.portfolio {
  grid-area: Portfolio;
  font-family: $specialfont, serif;
  color: $lightpurple;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  text-align: center;
}

.Project5 {
  grid-area: Project5;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  background-color: $vanilla;
}

.Project4 {
  grid-area: Project4;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  background-color: $vanilla;
}

.Project3 {
  grid-area: Project3;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  background-color: $vanilla;
}

.Project2 {
  grid-area: Project2;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  background-color: $vanilla;
}

.Project1 {
  grid-area: Project1;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  background-color: $vanilla;
}

.resume {
  grid-area: resume;
  font-family: $specialfont, serif;
  color: $lightpurple;
  background-color: $vanilla;
  border: 5px solid $greysea;
  padding: 20px;
  margin: 25px;
  text-align: center;
}

.resume-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    ' . . resume  . . '
    ' . img img img . '
} 

.resume-img {
  grid-area: img;
  max-width: 100%;
  margin: 0 0 50px 0;
}

// Footer
footer {
  color: white;
  text-align: center;
  background-color: $darkest;
  position: absolute;
  bottom: 0;
  padding: 30px;
  font-family: $specialfont;
}

// sticky footer
html, body { height: 100%;}

body > footer {
  position: sticky;
  top: 500vh;
}

//responsiveness

@media only screen and (min-width: 1024px) {
  nav {
    display:flex;
  }
}